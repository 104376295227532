import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import WhitePageContent from "../StyledElements/whitePageContent"
import parse from "html-react-parser"

const Bottomsection = () => {
  const allWpPage = useStaticQuery(graphql`
    query whitesection {
      allWpPage(filter: { isFrontPage: { eq: true } }) {
        nodes {
          ACFforside {
            bundSektionHvid
          }
        }
      }
    }
  `)

  return (
    <WhitePageContent>
      <div>
        {parse(`${allWpPage.allWpPage.nodes[0].ACFforside.bundSektionHvid}`)}
      </div>
    </WhitePageContent>
  )
}

export default Bottomsection
