import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Logogrid from "../components/Forside/logogrid"
import SplitBlue from "../components/Forside/splitblue"
import Bottomsection from "../components/Forside/bottomsection"
import parse from "html-react-parser"
import Seo from "gatsby-plugin-wpgraphql-seo"

const index = ({ data }) => {
  const { allWpPage } = data

  return (
    <>
      <Seo post={allWpPage.nodes[0]} />
      <Layout>
        <HeroWrap>
          <GatsbyImage
            image={
              allWpPage.nodes[0].ACFforside.heroimgForside.localFile
                .childImageSharp.gatsbyImageData
            }
            alt={allWpPage.nodes[0].ACFforside.heroimgForside.altText}
          />
          <HeroText>
            <Title>{allWpPage.nodes[0].ACFforside.heroHeading}</Title>
            <ul>
              {allWpPage.nodes[0].ACFforside.heroListElements.map(
                (heroListElements, id) => (
                  <ListItems key={id}>
                    {parse(`${heroListElements.listElement}`)}
                  </ListItems>
                )
              )}
            </ul>
            <BtnWrapper>
              <BtnLink to={allWpPage.nodes[0].ACFforside.heroKnap.heroKnapUrl}>
                {allWpPage.nodes[0].ACFforside.heroKnap.heroKnapTekst}
              </BtnLink>
            </BtnWrapper>
          </HeroText>
        </HeroWrap>
        <Logogrid />
        <SplitBlue />
        <Bottomsection />
      </Layout>
    </>
  )
}

export default index

export const query = graphql`
  {
    allWpPage(filter: { isFrontPage: { eq: true } }) {
      nodes {
        id
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        ACFforside {
          heroHeading
          heroKnap {
            heroKnapTekst
            heroKnapUrl
          }
          heroListElements {
            listElement
          }
          heroimgForside {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`

const Title = styled.h1`
  font-size: 32px;
  font-weight: 500;
  color: #eff1ef;
  max-width: 600px;
`

const HeroWrap = styled.section`
  height: 100%;
  width: 100%;
  color: #eff1ef;
  position: relative;
  display: inline-block;
`

const HeroText = styled.div`
  @media (min-width: 801px) {
    position: absolute;
    bottom: 45%;
    right: 9%;
  }

  @media (max-width: 800px) {
    background-color: rgb(69, 82, 96);
    padding: 50px 50px;
  }
`

const ListItems = styled.li`
  list-style-type: square;
  color: #eff1ef;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 20px;
  &::marker {
    color: #c6af6d;
  }

  & a {
    color: #eff1ef;
  }

  @media (max-width: 800px) {
    font-size: 18px;
  }
`

const BtnLink = styled(Link)`
  border-radius: 4px;
  background: #c6af6d;
  padding: 10px 22px;
  color: #eff1ef;
  font-size: 18px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-top: 20px;

  margin-left: 24px;
  &:hover {
    background-color: #FFC055;
  }
`

const BtnWrapper = styled.div`
  padding-top: 20px;
`
